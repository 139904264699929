/* Lato 300 - Light */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  src: url("../../public/assets/fonts/Lato-Light.ttf") format("truetype");
}

/* Lato 400 - Regular */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: url("../../public/assets/fonts/Lato-Regular.ttf") format("truetype");
}

/* Lato 700 - Bold */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  src: url("../../public/assets/fonts/Lato-Bold.ttf") format("truetype");
}
