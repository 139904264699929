/*

--- 01 TYPOGRAPHY SYSTEM

- Font sizes (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

- Font weights
Default: 400
Medium: 500
Semi-bold: 600
Bold: 700

- Line heights
Default: 1
Small: 1.05
Medium: 1.2
Paragraph default: 1.6

- Letter spacing
-0.5px
0.75px

--- 02 COLORS

- Primary: 
#CD4747

- Tints: 
#d76c6c
#e6a3a3
#faeded

- Shades: 
#903232
#521c1c
#290e0e


- Accents and CTA: 
#FFBC42

- Greys:
#888
#767676 (lightest grey allowed on #fff)
#6f6f6f (lightest grey allowed on #fdf2e9)
#555
#333

--- 05 SHADOWS

box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.075);


--- 06 BORDER-RADIUS

Default: 9px
Medium: 11px

--- 07 WHITESPACE

- Spacing system (px)

2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128
*/

@import url("/src/styles/fonts.css");

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

/* ///////////////////////////////////////////////////////////////////////////  */
/* LANDING PAGE */
/* /////////////////////////////////////////////////////////////////////////// */

body {
  max-width: 120rem;
  margin: 0 auto;
  padding: 2rem 2rem;

  color: #290e0e;
  background: #e6a3a3;
  background: linear-gradient(90deg, #e6a3a3 0%, #faeded 100%);
  /* background: linear-gradient(45deg, #d76c6c 0%, #e6a3a3 100%); */

  font-family: "Lato", sans-serif;
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  cursor: pointer;

  transition: all 0.3s;
}

button:hover {
  color: #cd4747;
}

.nav-container {
  height: 6rem;
  color: inherit;

  padding: 0 2rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  max-width: 230px;
  min-width: 50px;
  height: auto;
  cursor: pointer;

  transform: translateY(4px);
}

.logo-link {
  text-decoration: none;
  display: inline-block;
  width: auto;
  height: auto;

  display: flex;
  align-items: center;
}

.nav-items {
  font-size: 2rem;

  display: flex;
  gap: 4rem;
}

.blog-btn {
  color: #290e0e;
}

.newsletter-overlay {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.newsletter-content {
  background: linear-gradient(90deg, #e6a3a3 0%, #faeded 100%);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4rem;

  width: 60rem;
  height: auto;
  padding: 4rem;
  border-radius: 5px;
  position: relative;
  border: 3px solid #faeded;
}

.newsletter-content h2 {
  font-size: 3.2rem;
}

.newsletter-content p {
  width: 70%;
  font-size: 2rem;
  line-height: 1.4;
}

.newsletter-content form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.newsletter-content input {
  width: 40rem;
  margin: 1rem 4rem;
  padding: 1rem 1rem;

  background-color: #faeded;
  border: none;
  border-radius: 5px;
}

.newsletter-button {
  margin-top: 1rem;
  padding: 2rem 4rem;
  background-color: #cd4747;
  color: #faeded;
  border-radius: 5px;
  font-size: 2rem;
  text-transform: uppercase;
}

.newsletter-button:hover,
.newsletter-button:active {
  color: #fff;
}

.newsletter-image {
  margin-top: 4rem;
  width: 30%;
}

.newsletter-cancel-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  background-color: #cd4747;
  padding: 1.6rem 2rem;
  color: #faeded;
  border-radius: 5px;
}

.newsletter-cancel-button:hover {
  color: #faeded;
  transform: scale(1.03);
}

.newsletter-error {
  color: #cd4747;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.thanks-message {
  text-align: center;
  font: 4rem;
  font-weight: 700;
  color: #cd4747;
}

.main-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;

  margin-top: 8rem;

  padding-bottom: 4rem;
  border-bottom: 1px solid #d76c6c;
}

.main-section h1 {
  font-size: 4rem;
  letter-spacing: 2px;
  color: #cd4747;
}

.main-section h2 {
  font-size: 2rem;
  font-weight: 400;
  color: inherit;

  margin-bottom: 1rem;
}

.main-section h3 {
  font-size: 1.6rem;
  font-weight: 400;
  color: inherit;
}

.main-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 4rem;
  margin-bottom: 12rem;
}

.search {
  width: 40rem;

  border: 2px solid #e6a3a3;
  background-color: #faeded;
  border-radius: 8px;
  padding: 0.1rem 1.6rem;

  display: flex;
  align-items: center;
  justify-content: end;
  gap: 1rem;
}

.search-input {
  flex: 1;
  border: none;
  background: none;
  outline: none;
  padding: 0.5rem;
  font-size: 2rem;
}

.search svg {
  fill: #cd4747;
  cursor: pointer;
}

.search:focus-within {
  border: 2px solid #cd4747;
}

.search-results {
  max-height: 200px;
  overflow-y: auto;
  border-radius: 8px;
  width: 40rem;
}

.search-preview {
  display: flex;
  align-items: center;
  gap: 0 1rem;

  font-size: 1.6rem;
  padding: 1rem 1rem;
  background-color: rgba(250, 237, 237, 0.95);
  border-bottom: 1px solid rgba(205, 71, 71, 0.25);
  cursor: pointer;
}

.search-preview:hover {
  background-color: #faeded;
}

.search-thumbnail {
  height: 50px;
  width: 50px;
  object-fit: cover;
}

.category-item {
  height: 6rem;
  width: 18rem;
  border: 2px solid #d76c6c;
  border-radius: 8px;

  font-family: "Lato";
  font-size: 2rem;

  color: #290e0e;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.feature-item svg {
  height: 4rem;
  fill: #cd4747;
}

.category-item:hover {
  background-color: #cd4747;
  color: #fff;
}

.category-item:active {
  transform: translateY(3px);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.feature-highlights {
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 4rem;
}

.secondary-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8rem;
  margin-top: 4rem;

  border-bottom: 1px solid #d76c6c;
  padding-bottom: 4rem;
}

.secondary-description {
  font-size: 2rem;
  width: 60%;
  line-height: 1.4;
}

.secondary-description h2 {
  margin-bottom: 8rem;
}

.secondary-image {
  width: 20%;
}

.tertiary-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8rem;
  margin-top: 4rem;

  padding-bottom: 4rem;
  border-bottom: 1px solid #d76c6c;
}

.tertiary-description {
  font-size: 2rem;
  width: 60%;
  line-height: 1.4;
}

.tertiary-description h2 {
  margin-bottom: 8rem;
}

.tertiary-image {
  width: 25%;
}

.tertiary-features {
  width: 100%;

  display: grid;
  grid-template-rows: repeat(4, 1fr);

  padding-bottom: 4rem;
  justify-items: stretch;
  align-items: start;
  gap: 2rem;
}

.feature-item {
  display: flex;
  justify-content: start;
  align-items: center;
  flex: 0 0 30%;

  gap: 1rem;
  font-size: 1.8rem;
}

.tertiary-features > div {
  width: 100%;

  display: flex;
  flex-direction: row;
  gap: 2rem;

  align-items: center;

  min-width: 0;
  flex-grow: 1;
  flex-shrink: 1;
}

.tertiary-features > div > span {
  font-size: 1.8rem;
  flex: 0 0 70%;
  text-align: start;
}

.footer {
  width: 100%;
  color: #290e0e;
  margin-top: 4rem;
  font-size: 1.6rem;

  padding: 2rem 2rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-links {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.footer-links a {
  color: #cd4747;
  text-decoration: none;
}

.footer-text {
  text-align: center;
  align-self: end;
}

.social-media {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 3rem;
}

.social-media a svg {
  fill: #cd4747;
  width: 3rem;
  height: 3rem;
}

/* .social-media a:first-child svg {
  width: 3.5rem;
  height: 3.5rem;
} */

/* ///////////////////////////////////////////////////////////////////////////  */
/* FINDER */
/* /////////////////////////////////////////////////////////////////////////// */

.logo-finder {
  max-width: 200px;
  min-width: 50px;
  height: auto;
  cursor: pointer;

  transform: translateY(4px);
}

.nav-finder-container {
  height: 12rem;
  color: inherit;

  padding: 0 2rem;

  display: flex;
  justify-content: space-between;

  position: sticky;
  top: 0;
  transition: all 0.3s;
  z-index: 1000;
}

.nav-finder-container.solid {
  background-color: #e6a3a3;
  opacity: 0.95;
  padding: 2rem 2rem;

  height: 16rem;
}

.searchFinder {
  width: 40rem;

  border: 2px solid #e6a3a3;
  background-color: #faeded;
  border-radius: 8px;
  padding: 0.1rem 1.6rem;

  display: flex;
  align-items: center;
  justify-content: end;
  gap: 1rem;
}

.nav-finder-menu {
  font-size: 1.6rem;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  color: #cd4747;
  fill: #cd4747;
}

.nav-finder-menu svg {
  height: 3rem;
  width: auto;
  align-self: center;
  vertical-align: middle;
}

.searchFinder:focus-within {
  width: 42rem;
  border: 2px solid #cd4747;
}

.nav-finder-menu-items {
  /* line-height: 0.5; */
  width: 16rem;

  border: 2px solid #e6a3a3;
  background-color: #faeded;
  border-radius: 8px;
  padding: 0.2rem 1.6rem;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.disclaimer-container {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 2rem;
}

.disclaimer-container .feature-item {
  flex: 0 0 3%;
}

.disclaimer-container .feature-item svg {
  width: 26px;
  /* height: 26px; */
  display: flex;
  justify-content: start;
  align-items: center;

  gap: 0;
}

.disclaimer {
  /* text-align: center; */
  font-size: 1.4rem;
}

.content-area {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 4rem;
}

.items-container {
  /* height: 25rem; */
  background-color: #faeded;

  display: grid;
  grid-template-columns: 200px 5fr 3fr;
  grid-template-rows: auto auto;
  gap: 0 30px;
  align-items: center;

  border: 1px solid #e6a3a3;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  border-radius: 5px;
}

.items-image {
  grid-column: 1;
  grid-row: 1 / -1;
  width: 100%;
  height: 100%;
  object-fit: contain;
  /* border: 10px solid transparent; */
}

.text-container {
  align-self: start;

  grid-column: 2;
  grid-row: 1 / -1;

  display: flex;
  flex-direction: column;
  gap: 2rem;

  padding-top: 1.6rem;
}

.items-title {
  font-size: 2rem;
}

.items-description {
  font-size: 1.6rem;
  padding-bottom: 2rem;
}

.read-more-button {
  color: #cd4747;
  text-decoration: underline;
  cursor: pointer;
}

/* Square layout for links */
.link-container {
  padding-top: 3rem;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  justify-items: center;
  align-items: center;
  justify-content: center;
  align-self: end;
  gap: 2.5rem;

  text-decoration: underline;
  color: #cd4747;
}

.link-container a {
  display: flex;
  align-items: center;

  font-size: 1.6rem;
  text-decoration: none;
  color: #cd4747;
  background-color: #faeded;
  /* padding: 2rem 1rem; */
  border: 5px;
}

.link-icon {
  width: 1.6rem;
  height: 1.6rem;
  fill: #cd4747;

  transform: translateY(1px);
}

.items-button {
  grid-column: 3 / 4;
  grid-row: 2 / 3;
  align-self: end;
  justify-self: center;

  padding: 2rem 4rem;
  border-radius: 5px 0px 0px 0px;

  background-color: #cd4747;
  color: #faeded;
  font-size: 1.6rem;

  margin-bottom: 2rem;

  transition: all 0.2s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
}

.items-button:hover {
  color: #fff;
}

.items-button:active {
  transform: translateY(3px);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

.filter {
  position: sticky;
  top: 20px;
  height: calc(100vh - 40px);
  padding: 20px;
  border: 1px solid #cd4747;

  border-radius: 4px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 1199;
}

.modal-content {
  position: relative;

  background-color: transparent;

  padding: 2rem;
  border-radius: 3px;
  text-align: center;

  max-width: 800px;
  margin: 0 auto;
}

.categories-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);

  align-items: center;
  justify-items: center;

  justify-content: start;
  align-content: start;

  gap: 2rem;

  list-style: none;
  padding: 0;
}

.category-button {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 6rem;
  width: 14rem;
  padding: 2rem 4rem;
  background-color: #e6a3a3;

  border-radius: 3px;
}

.category-button:hover {
  background-color: #cd4747;
  color: #faeded;
  transition: all 0.3s;
  cursor: pointer;
}

.categories-list a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 1.6rem;
  color: #290e0e;
  text-decoration: none;
}

.cancel-button {
  font-size: 1.6rem;

  padding: 1rem 2rem;
  background-color: #cd4747;
  color: #faeded;
  border: none;
  border-radius: 3px;
  cursor: pointer;

  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);

  z-index: 9999;
}

.cancel-button:hover {
  color: #faeded;
}

.cancel-button:hover span {
  transform: scale(1.5);
  display: inline-block;
  transition: transform 0.2s;
}

.spinner {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  width: 60px;
  height: 40px;
  margin: 100px auto;
}

.bounce1,
.bounce2,
.bounce3 {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  animation: bounce 1.4s infinite ease-in-out;
  background: linear-gradient(135deg, #e6a3a3 0%, #cd4747 100%);
}

.bounce1 {
  animation-delay: -0.32s;
  animation-duration: 1.2s;
}

.bounce2 {
  animation-delay: -0.16s;
  animation-duration: 1.2s;
}

.bounce3 {
  animation-duration: 1.2s;
}

@keyframes bounce {
  0%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
}

.highlight {
  transform: scale(1.025);
  border: 3px solid #cd4747;
  border-radius: 3px;
}

/* ///////////////////////////////////////////////////////////////////////////  */
/* BLOG */
/* /////////////////////////////////////////////////////////////////////////// */

.blog-page {
  font-size: 2rem;
  margin-top: 10rem;
  text-align: center;
}

.blog-text {
  font-size: 3rem;
}

.blog-button {
  margin-top: 6rem;
  padding: 2rem 4rem;
  background-color: #cd4747;
  color: #faeded;
  border-radius: 5px;
  font-size: 2rem;
  text-transform: uppercase;
}

.blog-button:hover {
  color: #faeded;
  transform: scale(1.03);
}

/* ///////////////////////////////////////////////////////////////////////////  */
/* RESOURCES */
/* /////////////////////////////////////////////////////////////////////////// */

.resources-page {
  margin-top: 4rem;
  text-align: center;
}

.resources-page h1 {
  font-size: 4rem;
}

.resources-page p {
  font-size: 2rem;
  margin: 4rem 4rem;
  text-align: left;
}

/* ///////////////////////////////////////////////////////////////////////////  */
/* ABOUT US */
/* /////////////////////////////////////////////////////////////////////////// */

.about-page {
  margin-top: 4rem;
  text-align: center;
}

.about-page h1 {
  font-size: 4rem;
}

.about-page p {
  font-size: 2rem;
  margin: 4rem 4rem;
  text-align: left;
}

/* ///////////////////////////////////////////////////////////////////////////  */
/* CONTACT US */
/* /////////////////////////////////////////////////////////////////////////// */

.contact-page {
  margin-top: 4rem;
  text-align: center;
}

.contact-page h1 {
  font-size: 4rem;
}

.contact-page p {
  font-size: 2rem;
  margin: 4rem 4rem;
  text-align: left;
}

/* ///////////////////////////////////////////////////////////////////////////  */
/* MEDIA QUERIES */
/* /////////////////////////////////////////////////////////////////////////// */

@media only screen and (max-width: 75em) {
  html {
    font-size: 56.25%;
  }

  .logo-finder {
    max-width: 180px;
  }

  .disclaimer-container .feature-item {
    flex: 0 0 5%;
  }
}

@media only screen and (max-width: 48em) {
  html {
    font-size: 52.25%;
  }

  .logo {
    max-width: 200px;
  }

  .main-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    gap: 4rem;
    margin-bottom: 8rem;
  }

  .feature-item svg {
    height: 4rem;
  }

  .feature-highlights {
    display: grid;
    grid-template-columns: repeat(1, auto);
    gap: 4rem;
  }

  .secondary-section {
    position: relative;
  }

  .secondary-description h2 {
    text-align: left;
  }

  .secondary-description {
    width: 100%;
    position: relative;
    z-index: 2;
  }

  .secondary-image {
    position: absolute;
    top: 0;
    right: 10%;
    width: auto;
    height: 15%;
    z-index: 1;
  }

  .tertiary-section {
    position: relative;
  }

  .tertiary-description {
    width: 100%;
    position: relative;
    z-index: 2;
  }

  .tertiary-image {
    position: absolute;
    top: 0;
    left: 10%;
    width: auto;
    height: 8%;
    z-index: 1;
  }

  .tertiary-features {
    width: 100%;

    display: grid;
    grid-template-rows: repeat(4, 1fr);

    margin: 6rem auto;
    justify-items: stretch;
    align-items: start;
    gap: 4rem;
  }

  .tertiary-description h2 {
    text-align: right;
  }

  .feature-item {
    display: flex;
    justify-content: start;
    align-items: center;
    flex: 0 0 30%;

    gap: 1rem;
    font-size: 1.8rem;
  }

  .tertiary-features > div {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    text-align: center;
  }

  .tertiary-features > div > span {
    text-align: center;
  }

  .footer-text {
    font-size: 1.2rem;
  }

  .nav-finder-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    align-items: center;
  }

  .logo-link {
    grid-column: 1 / 2;
    grid-row: 1 / 2;

    justify-self: start;
  }

  .nav-finder-menu {
    display: contents;
  }

  .nav-finder-menu-items {
    grid-column: 2 / 3;
    grid-row: 1 / 2;

    justify-self: end;
  }

  .searchFinder {
    grid-column: 1 / -1;
    grid-row: 2 / 3;

    width: auto;
    justify-self: stretch;
  }

  .items-container {
    display: grid;
    grid-template-columns: 150px 5fr 3fr;
    grid-template-rows: auto auto;
    gap: 10px;
  }

  .items-button {
    padding: 1rem 2rem;
  }

  .link-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: 5px;
  }
}

@media only screen and (max-width: 40em) {
  .logo {
    max-width: 125px;
  }

  .logo-finder {
    max-width: 120px;
  }

  .disclaimer-container .feature-item {
    flex: 0 0 7%;
  }

  .newsletter-content {
    gap: 2rem;
    width: 40rem;
    height: 80rem;
    padding: 4rem;
  }

  .newsletter-content h2 {
    font-size: 2.4rem;
  }

  .newsletter-content p {
    width: 90%;
    font-size: 1.8rem;
    line-height: 1.4;
  }

  .newsletter-content input {
    width: 26rem;
    margin: 1rem 0;

    background-color: #faeded;
    border: none;
    border-radius: 5px;
  }
  .newsletter-button {
    padding: 1.6rem 2.6rem;
    color: #faeded;
    font-size: 2rem;
  }

  .newsletter-image {
    margin-top: 4rem;
    width: 50%;
  }

  .newsletter-cancel-button {
    top: 1rem;
    right: 1rem;
    font-size: 1.2rem;
    background-color: #cd4747;
    color: #faeded;
    padding: 0.8rem 1.2rem;
  }
}

@media only screen and (max-width: 35em) {
  .modal-content {
    max-width: 36rem;
  }

  .categories-list {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }

  .category-button {
    height: 6rem;
    width: 14rem;
    padding: 2rem 4rem;
  }
}

@media only screen and (max-width: 30em) {
  .nav-container {
    height: 6rem;
    color: inherit;

    padding: 0 2rem;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .nav-items {
    font-size: 1.8rem;
    gap: 2rem;
  }

  .main-section h1 {
    font-size: 3.6rem;
  }

  .main-section h2 {
    font-size: 1.8rem;
  }

  .footer-text {
    font-size: 1rem;
  }

  .footer-links {
    gap: 1rem;
  }

  .footer-links a {
    color: #cd4747;
    text-decoration: none;
    font-size: 1.4rem;
  }
  .social-media {
    gap: 2rem;
  }
  .social-media a svg {
    width: 2.6rem;
    height: 2.6rem;
  }

  .searchFinder:focus-within {
    width: 100%;
  }

  .disclaimer-container .feature-item {
    flex: 0 0 10%;
  }

  /* .nav-finder-container {
    padding: 1rem 2rem;
    height: 12rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;

    position: sticky;
    top: 0;
    transition: all 0.3s;
    z-index: 1000;
  } */

  .items-container {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-template-rows: 250px auto 1fr;
    gap: 30px;
    align-items: center;
  }

  .items-image {
    width: 100%;
    object-fit: fill;

    grid-column: 1 / -1;
    grid-row: 1 / 2;
  }

  .text-container {
    grid-column: 1 / -1;
    grid-row: 2 / 3;

    padding: 0 2rem;
  }

  .link-container {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    justify-items: start;

    padding: 2rem 2rem;
  }

  .items-button {
    grid-column: 2 / 3;
    grid-row: 3 / 4;

    padding: 1rem 2rem;
    margin-right: 1rem;
  }
}

@media only screen and (max-width: 21em) {
  html {
    font-size: 40%;
  }

  .items-image {
    max-height: auto;
  }
}
